let map;
let locations = JSON.parse(walk_map_vars.galleries_data);
const startPosition = { lat: 52.5037644, lng: 13.3120762 };
const zoom = 14.85;

function loadStyles(path) {
	return jQuery.getJSON(path);
}

function initializeMap(styles) {
	map = new google.maps.Map(document.getElementById('map'), {
		center: startPosition,
		zoom: zoom,
		styles: styles,
		mapTypeControl: false,
		streetViewControl: false,
		fullscreenControl: false
	});

	let infoWindow = new google.maps.InfoWindow();

	locations.forEach((location, index) => {
		locations.forEach((location, index) => {

			let marker = new google.maps.OverlayView();

			marker.onAdd = function() {
				let div = document.createElement('div');
				div.style.position = 'absolute';
				div.innerHTML = ('0' + (index + 1)).slice(-2);
				div.className = 'custom-marker';
				this.div = div;

				let panes = this.getPanes();
				panes.overlayImage.appendChild(div);
				google.maps.event.addDomListener(div, 'click', function() {

					let content = '';
					if (location.gallery) {
						content += '<div class="gallery-name">' + location.gallery + '</div>';
					}
					if (location.street) {
						content += '<div>' + location.street + '</div>';
					}
					// if (location.post_code || location.city) {
					// 	content += '<div>' + (location.post_code ? location.post_code : '') + ' ' + (location.city ? location.city : '') + '</div>';
					// }
					if (location.url) {
						content += '<div><a href="' + location.url + '">Galerie / Exhibition</a></div>';
					}

					if (location.google_maps_link) {
						content += '<div><p></p><a href="' + location.google_maps_link + '"target="_blank" rel="noopener noreferrer">Goolge Maps Route</a></div>';
					}

					infoWindow.setContent(content);
					infoWindow.setPosition(new google.maps.LatLng(location.lat, location.lng));
					infoWindow.open(map);
				});
			};
			marker.draw = function() {
				let overlayProjection = this.getProjection();
				let position = overlayProjection.fromLatLngToDivPixel(new google.maps.LatLng(location.lat, location.lng));
				let div = this.div;
				div.style.left = position.x + 'px';
				div.style.top = position.y + 'px';
			};
			marker.onRemove = function() {
				this.div.parentNode.removeChild(this.div);
				this.div = null;
			};
			marker.setMap(map);
		});
	});
}

window.initMap = function() {
	loadStyles(walk_map_vars.map_style_path).done(initializeMap);
}